export const CREATE_REPORT_SUCCESS = " CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_ERROR = "CREATE_REPORT_ERROR";
export const CREATE_REPORT_REQUEST = "CREATE_REPORT_REQUEST";

export const GET_DOCTOR_SUCCESS = " GET_DOCTOR_SUCCESS";
export const GET_DOCTOR_ERROR = "GET_DOCTOR_ERROR";
export const GET_DOCTOR_REQUEST = "GET_DOCTOR_REQUEST";

export const GET_APPOINTMENT_SUCCESS = " GET_APPOINTMENT_SUCCESS";
export const GET_APPOINTMENT_ERROR = "GET_APPOINTMENT_ERROR";
export const GET_APPOINTMENT_REQUEST = "GET_APPOINTMENT_REQUEST";

export const ADD_PATIENT_SUCCESS = " ADD_PATIENT_SUCCESS";
export const ADD_PATIENT_ERROR = "ADD_PATIENT_ERROR";
export const ADD_PATIENT_REQUEST = "ADD_PATIENT_REQUEST";

export const ADD_BED_SUCCESS = " ADD_BED_SUCCESS";
export const ADD_BED_ERROR = "ADD_BED_ERROR";
export const ADD_BED_REQUEST = "ADD_BED_REQUEST";

export const GET_BED_SUCCESS = " GET_BED_SUCCESS";
export const GET_BED_ERROR = "GET_BED_ERROR";
export const GET_BED_REQUEST = "GET_BED_REQUEST";

export const CREATE_BOOKING_SUCCESS = " CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_ERROR = "CREATE_BOOKING_ERROR";
export const CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST";

export const CREATE_PAYMENT_SUCCESS = " CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_ERROR = "CREATE_PAYMENT_ERROR";
export const CREATE_PAYMENT_REQUEST = "CREATE_PAYMENT_REQUEST";

export const ADD_BEDS_SUCCESS = " ADD_BEDS_SUCCESS";
export const ADD_BEDS_ERROR = "ADD_BEDS_ERROR";
export const ADD_BEDS_REQUEST = "ADD_BEDS_REQUEST";

export const GET_SINGLE_BEDS_SUCCESS = " GET_SINGLE_BEDS_SUCCESS";
export const GET_SINGLE_BEDS_ERROR = "GET_SINGLE_BEDS_ERROR";
export const GET_SINGLE_BEDS_REQUEST = "GET_SINGLE_BEDS_REQUEST";

export const EDIT_SINGLE_BEDS_SUCCESS = " EDIT_SINGLE_BEDS_SUCCESS";
export const EDIT_SINGLE_BEDS_ERROR = "EDIT_SINGLE_BEDS_ERROR";
export const EDIT_SINGLE_BEDS_REQUEST = "EDIT_SINGLE_BEDS_REQUEST";

export const DISCHARGE_PATIENT_SUCCESS = " DISCHARGE_PATIENT_SUCCESS";
export const DISCHARGE_PATIENT_ERROR = "DISCHARGE_PATIENT_ERROR";
export const DISCHARGE_PATIENT_REQUEST = "DISCHARGE_PATIENT_REQUEST";

export const GET_PATIENT_SUCCESS = " GET_PATIENT_SUCCESS";
export const GET_PATIENT_ERROR = "GET_PATIENT_ERROR";
export const GET_PATIENT_REQUEST = "GET_PATIENT_REQUEST";

export const GET_APPOINTMENT_DETAILS_SUCCESS =
  " GET_APPOINTMENT_DETAILS_SUCCESS";
export const GET_APPOINTMENT_DETAILS_ERROR = "GET_APPOINTMENT_DETAILS_ERROR";
export const GET_APPOINTMENT_DETAILS_REQUEST =
  "GET_APPOINTMENT_DETAILS_REQUEST";

export const GET_ALLDATA_SUCCESS = " GET_ALLDATA_SUCCESS";
export const GET_ALLDATA_REQUEST = "GET_ALLDATA_REQUEST";

export const DELETE_APPOINTMENT_SUCCESS = " DELETE_APPOINTMENT_SUCCESS";
export const DELETE_APPOINTMENT_REQUEST = "DELETE_APPOINTMENT_REQUEST";

export const GET_REPORTS_SUCCESS = " GET_REPORTS_SUCCESS";
export const GET_REPORTS_REQUEST = "GET_REPORTS_REQUEST";
